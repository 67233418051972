import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MuiAlert from "@material-ui/lab/Alert";
import { forgotPassword } from "../actions/auth";
import Copyright from "../components/ui/Copyright";
import { validateEmailFormat } from "../components/utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
  paper: {
    backgroundColor: "transparent",
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPasswordPage() {
  const auth = useSelector((state) => state.auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setError(auth.response);
    
  }, [auth.response]);

  // once user submits, we want to dispatch a post request to reset password
  const handleSubmit = (e) => {
    e.preventDefault();
    const url = window.location.href;
    if (validateEmailFormat(email)) {
      dispatch(forgotPassword(email, history, url));
    } else {
      setError("Email format is incorrect!");
      setTimeout(function () {
        setError(null);
      }, 3000);
      return;
    }
  };

  return (
    <>
      <Container component='main' maxWidth='xs' style={{ height: "100vh" }}>
        <CssBaseline />
        <div
          className={classes.paper}
          style={{ backgroundColor: "transparent" }}
        >
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Forgot Password
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Enter Your Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              onChange={(e) => {
                setEmail(e.target.value);
                }               
              }
            />
            
            {/* SET THE ERROR */}

            {error && (
              <Alert
                severity='error'
                onClick={() => {
                  dispatch({ type: "ADD_ERROR", payload: "" });
                  // dispatch({ type: "EMAIL_ERROR" });
                }}
              >
                {auth.response || error}
              </Alert>
            )}

            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={handleSubmit}
            >
              Submit
            </Button>

            <Grid container>
              <Grid item>
                <Link href='/login' variant='body2'>
                  {"Go back to LogIn Page"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
