import React from "react";
import classes from "./AboutPage.module.scss";

const AboutPage = () => {
  return (
      <div className={classes.container}>
        <div>
          <img src="https://hansschmidt.org/wp-content/uploads/2021/11/Eppy-standard-pose.png" width="25%" align="right"></img>
          <h2>About EveryPast</h2>
          <div className={classes.line}></div>
          <p>
            EveryPast is a project started in 2021 by Josiah Schmidt.
            <p>
              Josiah Schmidt is a professional genealogist and PhD candidate in
              the History Department at Washington University in St Louis. In
              2014, he published <strong><i>2000 Questions for Grandparents: Unlocking Your
              Family’s Hidden History</i></strong>, a book containing a brief guide on
              conducting family history interviews and a list of two thousand
              prompt questions to get older relatives’ memories flowing.
            </p>
            <p>
              Several years later, after thousands of people had used this book to
              record their own or their loved one’s family history, Josiah had a
              vision of getting everyone’s story recorded, making sure that
              history reduced no one to a mere name and number.
            </p>
            <p>
              Josiah launched EveryPast, an ambitious project to build up a
              platform where everyone who is willing to tell their story, is able to
              have their story heard and preserved.
            </p>
            <p>
              Credit to Jake West and Lawrence Lee, the very talented web developers
              who helped EveryPast.com come to life. Thanks also to Imajin Studio
              for designing the Eppy mascot.
            </p>
          </p>
        </div>
      </div>
  );
};

export default AboutPage;
