export default (questions = [], action) => {
  switch (action.type) {
    case "FETCH_ALL":
      return action.payload.questions;

    case "UPDATE_QUESTIONS":
      return action.payload.questions;

    default:
      return questions;
  }
};
