import React from "react";
import { useDispatch } from "react-redux";
import styles from "./SecondaryUserBlock.module.scss";
import { Link } from "react-router-dom";
import { store } from "../../index";

import DeleteIcon from "@material-ui/icons/Delete";

import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { showLoader, hideLoader } from "../../actions/auth";
import { removeSecondaryUser } from "../../actions/user";
import { getUser } from "../../actions/user";
import { removeResponse } from "../../actions/auth";

const SecondaryUserBlock = ({
  first_name,
  last_name,
  loading,
  bday,
  id,
  setResponse,
}) => {
  const dispatch = useDispatch();
  const auth = store.getState().auth;

  const handleDelete = (e) => {
    var result = window.confirm(
      "Are you sure you want to permanently delete this user profile?"
    );
    if (result) {
      setResponse(auth.response);
      dispatch(showLoader());

      //Logic to delete the item
      dispatch(removeSecondaryUser(id));
      //   setChangeRender(changeRender + 1);
      dispatch(getUser(auth.id));

      // dispatch(getUser(auth.id));
      setTimeout(function () {
        dispatch(hideLoader());
      }, 500);
      setTimeout(function () {
        dispatch(removeResponse());
      }, 3000);
    }
  };

  if (!loading) {
    {
      console.log("NOT LOADING");
    }

    return (
      <div className={styles.containerQuestions}>
        <div className={styles.padding}>
          <div className={styles.textBox}>
            <div className={styles.textFlex}>
              <h3>First Name:</h3>
              <h2>{first_name}</h2>
            </div>

            <div className={styles.textFlex}>
              <h3>Last Name:</h3>
              <h2>{last_name}</h2>
            </div>

            <div className={styles.textFlex}>
              <h3>Birth Date:</h3>
              <h2>
                {bday.substring(5, 7)}/{bday.substring(8, 10)}/
                {bday.substring(0, 4)}
              </h2>
            </div>
          </div>
          <Link
            to={{
              pathname: `/edit/secondary-profile/${id}`,
              state: { id: id, first_name: first_name, last_name: last_name },
            }}
          >
            <button className={styles.viewInProfile}>
              Edit Profile <ChevronRightIcon className={styles.chevRight} />
            </button>
          </Link>

          <div className={styles.delete} onClick={handleDelete}>
            Delete User <DeleteIcon className={styles.editIcon} />
          </div>
        </div>
      </div>
    );
  } else {
    {
      console.log("LOADING");
    }
    return (
      <div
        style={{
          // backgroundColor: "rgba(0,0,0,1)",
          // position: "fixed",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress
          style={{
            position: "fixed",
            right: "50%",
            top: "50%",
          }}
        ></CircularProgress>
      </div>
    );
  }
};

export default SecondaryUserBlock;
