import classes from './subscriptions.module.scss'
import { subscriptions } from '../../helpers/subscriptions'

const Subscriptions = ({user, setSubscription}) => {
  
  return (
    <>
    <div className={classes.containerHeader}>
      <h1>Pricing</h1>
      <p>Quickly build an effective pricing table for your potential customers with this layout. It's built with default MUI components with little customization.</p>
    </div>
    <div className={classes.container}>
      {subscriptions && subscriptions.map((item, idx) => 
        <div key={idx} className={classes.subscriptionCard}>
          <div className={classes.subscriptionCardTitle}>{item.title}</div>
          <div className={classes.subscriptionPrice}><span>${item.price}</span> / mo</div>
          <div className={classes.subscriptionCardFeatures}>
            {item.features && item.features.map((feature) => 
                <span>{feature}</span>
            )}
          </div>
          {user.subscription == 'free' && item.title.toLowerCase() == 'basic' && 
          <button 
            disabled={true}
            className={classes.subscriptionCardButtonSelected}
            onClick={() => setSubscription(item)}
          >
            Current plan
          </button>
          }
          {user.subscription == 'free' && item.title.toLowerCase() == 'premium' && 
          <button 
            className={classes.subscriptionCardButton}
            onClick={() => setSubscription(item)}
          >
            Get Started
          </button>
          }
          {user.subscription !== 'free' && item.title.toLowerCase() == 'basic' && 
          <button 
            className={classes.subscriptionCardButton}
            onClick={() => setSubscription(item)}
          >
            Free plan
          </button>
          }
          {user.subscription !== 'free' && item.title.toLowerCase() == 'premium' && 
          <button 
            disabled={true}
            className={classes.subscriptionCardButtonSelected}
            onClick={() => setSubscription(item)}
          >
            Current plan
          </button>
          }
       </div>
      )}
    </div>
    </>
  )
}

export default Subscriptions
