import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MuiAlert from "@material-ui/lab/Alert";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Modal from "@material-ui/core/Modal";
import { validateEmailFormat } from "../components/utils";

import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../actions/user";

function Copyright() {
  return (
    <Typography
      variant='body2'
      color='textSecondary'
      align='center'
      style={{ marginTop: "20px !important" }}
    >
      {"Copyright © "}
      <Link color='inherit' href='/'>
        EVERYpast
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },

  paper: {
    background: "transparent",
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  margBot: {
    marginBottom: "20px !important",
  },
  helperText: {
    color: "#f44336",
    fontSize: "14px",
    width: "300px",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflowY: "scroll",
    height: "90%",
    width: "97%",
    display: "block",
  };
}

const useDyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "90%",
    },
  },
  paper: {
    position: "absolute",
    width: "70%",
    maxWidth: "600px",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function RegisterPage() {
  const classes = useStyles();
  const dyles = useDyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const [isChecked, setIsChecked] = useState(false);
  // const [isComsChecked, setIsComsChecked] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [helperText, setHelperText] = useState("");
  const [helperTextE, setHelperTextE] = useState("");
  const [helperTextB, setHelperTextB] = useState("");

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password1: "",
    bday: "",
    communication_consent: false,
  });

  const [error, setError] = useState("");

  useEffect(() => {
    setError(auth.response);
    // setError("");
  }, [auth.response]);

  // once user submits, we want to dispatch a post request, create user
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      userData.email === "" ||
      userData.password === "" ||
      userData.password1 === "" ||
      userData.first_name === "" ||
      userData.last_name === "" ||
      userData.bday === ""
    ) {
      setError("Fields are required");
      setTimeout(function () {
        setError("");
      }, 5000);
      return;
    }

    if (userData.password !== userData.password1) {
      setError("Passwords do not match");
      setTimeout(function () {
        setError("");
      }, 5000);
      return;
    }

    if (userData.password.length < 6 || userData.password1.length < 6) {
      setHelperText("Password must be 6 or more characters");
      setTimeout(function () {
        setHelperText("");
      }, 5000);
      return;
    }

    if (!validateEmailFormat(userData.email)) {
      setHelperTextE("Must be a valid email format");
      setTimeout(function () {
        setHelperTextE("");
      }, 5000);
      return;
    }

    if (userData.bday.length < 14) {
      setHelperTextB("Format must be MM/DD/YYYY (eg. 10/04/1987)");
      setTimeout(function () {
        setHelperTextB("");
      }, 5000);
      return;
    }
    dispatch(createUser(userData));
    dispatch({ type: "SENT_VALIDATE" });
  };

  // If SUCCESS, redirect
  if (user.first_name !== "") {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { response: auth.response },
        }}
      />
    );
  }

  // console.log(userData.bday);
  var date = document.getElementById("date");

  function checkValue(str, max) {
    if (str.charAt(0) !== "0" || str == "00") {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str =
        num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
          ? "0" + num
          : num.toString();
    }
    return str;
  }

  const handleBlur = (e) => {
    e.target.type = "text";
    var input = e.target.value;
    var values = input.split("/").map(function (v, i) {
      return v.replace(/\D/g, "");
    });
    var output = "";

    if (values.length == 3) {
      var year =
        values[2].length !== 4
          ? parseInt(values[2]) + 2000
          : parseInt(values[2]);
      var month = parseInt(values[0]) - 1;
      var day = parseInt(values[1]);
      var d = new Date(year, month, day);
      // if (!isNaN(d)) {
      //   document.getElementById("result").innerText = d.toString();
      //   var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
      //   output = dates
      //     .map(function (v) {
      //       v = v.toString();
      //       return v.length == 1 ? "0" + v : v;
      //     })
      //     .join(" / ");
      // }
    }
    e.target.value = output;
  };

  const handleInput = (e) => {
    e.target.type = "text";
    var input = e.target.value;
    console.log(input);
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values = input.split("/").map(function (v) {
      return v.replace(/\D/g, "");
    });
    if (values[0]) values[0] = checkValue(values[0], 12);
    if (values[1]) values[1] = checkValue(values[1], 31);
    var output = values.map(function (v, i) {
      return v.length == 2 && i < 2 ? v + " / " : v;
    });
    e.target.value = output.join("").substr(0, 14);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={dyles.paper}>
      <button className={classes.button} type='submit' onClick={handleClose}>
        Close
      </button>
    </div>
  );

  const terms = (
    <p>
      I agree to the{" "}
      <a href='/terms' target='_blank'>
        Terms and Conditions
      </a>
      .
    </p>
  );

  const comTerms = <p>I agree to receive communications from EveryPast</p>;

  return (
    <Container style={{ color: "black" }} component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                style={{ color: "black" }}
                autoComplete='fname'
                name='first_name'
                value={userData.first_name}
                variant='outlined'
                required
                fullWidth
                id='first_name'
                label='First Name'
                autoFocus
                onChange={(e) =>
                  setUserData({ ...userData, first_name: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                style={{ color: "black" }}
                variant='outlined'
                required
                fullWidth
                id='last_name'
                label='Last Name'
                name='last_name'
                value={userData.last_name}
                autoComplete='lname'
                onChange={(e) =>
                  setUserData({ ...userData, last_name: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                style={{ color: "black" }}
                variant='outlined'
                helperText={helperTextE}
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                value={userData.email}
                autoComplete='email'
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                id={date}
                style={{ color: "black" }}
                name='bday'
                value={userData.bday}
                helperText={helperTextB}
                variant='outlined'
                required
                fullWidth
                label='Date of Birth (MM/DD/YYYY)'
                onInput={handleInput}
                onBlur={handleBlur}
                onChange={(e) =>
                  setUserData({ ...userData, bday: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                variant='outlined'
                required
                fullWidth
                helperText={helperText}
                name='password'
                value={userData.password}
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                onChange={(e) =>
                  setUserData({ ...userData, password: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                variant='outlined'
                required
                fullWidth
                helperText={helperText}
                name='password1'
                value={userData.password1}
                label='Password'
                type='password'
                id='password1'
                autoComplete='current-password'
                onChange={(e) =>
                  setUserData({ ...userData, password1: e.target.value })
                }
              />
            </Grid>
            {/* SET THE ERROR */}
            {error && (
              <Alert
                severity='error'
                onClick={() => {
                  setError(null);
                  dispatch({ type: "LOGIN_FAIL" });
                }}
              >
                {auth.response || error}
              </Alert>
            )}

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value='allowExtraEmails'
                    color='primary'
                    checked={userData.communication_consent}
                  />
                }
                label={comTerms}
                onClick={() =>
                  setUserData({
                    ...userData,
                    communication_consent: !userData.communication_consent,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value='allowTerms'
                    color='primary'
                    checked={isChecked}
                  />
                }
                label={terms}
                onClick={() => setIsChecked(!isChecked)}
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            onClick={handleSubmit}
            disabled={!isChecked}
          >
            Sign Up
          </Button>
          <Grid container justify='flex-end' className={classes.margBot}>
            <Grid item>
              <Link href='/login' variant='body2'>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>

      <Copyright />

      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby='simple-modal-description'
      >
        {body}
      </Modal>
    </Container>
  );
}
