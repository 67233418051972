import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import styles from "./LoginPage.module.scss";

import { useLocation } from "react-router-dom";

import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MuiAlert from "@material-ui/lab/Alert";
import { removeResponse } from "../actions/auth";

import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/auth";
import Copyright from "../components/ui/Copyright";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
  paper: {
    backgroundColor: "transparent",
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// STYLE FOR MODAL
const useDyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "90%",
    },
  },
  paper: {
    position: "absolute",
    width: "70%",
    maxWidth: "600px",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function LoginPage() {
  const auth = useSelector((state) => state.auth);
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [response, setResponse] = useState("");

  const [error, setError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeResponse());
  }, []);

  useEffect(() => {
    setError(auth.response);

    // setError("");
  }, [auth.response]);

  const dyles = useDyles();

  const [open, setOpen] = useState(auth.sentValidate);

  const handleClose = () => {
    setOpen(false);
    dispatch({ type: "REMOVE_VALIDATE" });
  };

  // once user submits, we want to dispatch a post request, login
  const handleSubmit = (e) => {
    e.preventDefault();
    if (loginData.email === "" || loginData.password === "") {
      setError("Fields are required");
      setTimeout(function () {
        setError("");
      }, 3000);
      return;
    }

    dispatch(login(loginData));
  };

  // Name Modal Body
  const body = (
    <div style={modalStyle} className={dyles.paper}>
      <p id='simple-modal-description' className={styles.modalQuestion}>
        We sent a verification link to the email address associated with your
        account! Please click the link in your email to verify your account
        before logging in.
      </p>

      <button className={styles.submitButton} onClick={handleClose}>
        Continue to Sign in
      </button>
    </div>
  );

  if (auth.isAuthenticated === true) {
    return <Redirect to='/home' />;
  }

  return (
    <>
      <Container component='main' maxWidth='xs' style={{ height: "100vh" }}>
        {/* SET THE RESPONSE */}

        {auth.response === "Successfully deleted user" && (
          <Alert
            style={{ marginTop: "20px" }}
            severity='success'
            onClick={() => {
              dispatch({ type: "REMOVE_RESPONSE" });
            }}
          >
            {auth.response || response}
          </Alert>
        )}
        <CssBaseline />
        <div
          className={classes.paper}
          style={{ backgroundColor: "transparent" }}
        >
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              onChange={(e) =>
                setLoginData({ ...loginData, email: e.target.value })
              }
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              onChange={(e) =>
                setLoginData({ ...loginData, password: e.target.value })
              }
            />
            {/* SET THE ERROR */}

            {error !== "Successfully deleted user" && error && (
              <Alert
                severity='error'
                onClick={() => {
                  setError(null);
                  dispatch({ type: "LOGIN_FAIL" });
                }}
              >
                {auth.response || error}
              </Alert>
            )}
            {/* <FormControlLabel
            control={<Checkbox value='remember' color='primary' />}
            label='Remember me'
          /> */}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={handleSubmit}
            >
              Sign In
            </Button>

            <Grid container>
              <Grid item xs>
              <Link href='/forgot' variant='body2'>
                Forgot password?
              </Link>
            </Grid>
              <Grid item>
                <Link href='/register' variant='body2'>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby='simple-modal-description'
      >
        {body}
      </Modal>
    </>
  );
}
