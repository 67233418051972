const userTypes = {
    CREATE_USER: 'CREATE_USER',
    GET_USER: 'GET_USER',
    UPDATE_NAME: 'UPDATE_NAME',
    UPDATE_BDAY: 'UPDATE_BDAY',
    UPDATE_BIO: 'UPDATE_BIO',
    GET_ACCOUNT: 'GET_ACCOUNT',
    UPDATE_EMAIL: 'UPDATE_EMAIL',
    DELETE_ANSWER: 'DELETE_ANSWER',
    ADD_SECONDARY_USER: 'ADD_SECONDARY_USER',
    RESET_PASSWORD_START: 'RESET_PASSWORD_START',
    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS'
};

export default userTypes;