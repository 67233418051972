import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import CircularProgress from "@material-ui/core/CircularProgress";

// Provider allows us to keep track of the storage (global state), and allow access to it from anywhere in app
import { Provider } from "react-redux";
//
import { createStore, applyMiddleware } from "redux";
//
import thunk from "redux-thunk";
import reducers from "./reducers";

// THIS IS PROD ENVIRONMENT IMPORT
import { compose } from "redux";

// START DEV ENVIRONMENT
// import { composeWithDevTools } from "redux-devtools-extension";

// const middleware = [thunk];

// export const store = createStore(
//   reducers,
//   composeWithDevTools(
//     applyMiddleware(...middleware)
//     // other store enhancers if any
//   )
// );
// END DEV ENVIRONMENT

// THIS IS PROD STORE
export const store = createStore(reducers, compose(applyMiddleware(thunk)));

if (store.getState()) {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
} else {
  <CircularProgress
    style={{
      position: "absolute",
      right: "50%",
      top: "50%",
    }}
  ></CircularProgress>;
}
