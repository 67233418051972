import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import classes from "./QuestionBlock.module.scss";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CircularProgress from "@material-ui/core/CircularProgress";

import {useDispatch, useSelector} from "react-redux";

import {createAnswer} from "../../actions/answers";

import {getQuestions} from "../../actions/questions";
import {getSecondaryUserQuestions} from "../../actions/questions";
import {
    createSecondaryUserAnswer,
    getSecondaryUser,
} from "../../actions/user";

import {showLoader, hideLoader} from "../../actions/auth";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "85%",
            height: "75%",
        },
    },
    paper: {
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.background.paper,
        border: "none",
    },
}));

const QuestionBlock = ({
                           currentItemArray,
                           setCurrentItemArray,
                           text,
                           id,
                           userID,
                       }) => {
    const loading = useSelector((state) => state.auth.compLoad);
    const [answerData, setAnswerData] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    // now we can dispatch actions, but where to
    const dispatch = useDispatch();

    const styles = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (isSubmitted == true) {
            removeCurrentQuestion(id);
        }
        // dispatch(showLoader());
        setOpen(false);
        setIsSubmitted(false);
        // if (window.location.href.indexOf("edit") > -1) {
        //   dispatch(getSecondaryUserQuestions(userID));
        //   setTimeout(function () {
        //     dispatch(hideLoader());
        //   }, 1000);
        // } else {
        //   dispatch(getQuestions());
        //   setTimeout(function () {
        //     dispatch(hideLoader());
        //   }, 700);
        // }
    };

    const removeCurrentQuestion = (ID) => {
        const result = currentItemArray.filter((question) => question.id !== id);
        setCurrentItemArray(result);
    };

    const handleSubmit = (e) => {
        // once user submits, we want to send over a post request with all the data they typed in
        e.preventDefault();
        if (window.location.href.indexOf("edit") > -1) {
            dispatch(createSecondaryUserAnswer(userID, answerData, id));
            setTimeout(function () {
                dispatch(getSecondaryUser(userID));
            }, 2000);
        } else {
            dispatch(createAnswer(answerData, id));
        }

        setIsSubmitted(true);
        // When we submit, we want to remove this question from the currentItemArray
    };

    // useEffect(() => {
    //   dispatch(getQuestions());
    // }, [dispatch, open]);

    const stylesD = useStyles();

    const wordsInAnswer = answerData ? answerData.trim().split(' ').filter(w => w != "").length : 0;
    const wordCount = wordsInAnswer === 1 ? '1 word' : `${wordsInAnswer} words`;
    const wordGoal = 500;
    const wordsToGoal = wordGoal - wordsInAnswer;

    const body = (
        <div style={modalStyle} className={styles.paper}>
            <div style={modalStyle} className={classes.innerContainer}>
                <div id='simple-modal-description' className={classes.modalQuestion}>
                    <div style={{display: "table"}}>
                        <div style={{display: "table-row"}}>
                            <div style={{display: "table-cell", verticalAlign: "middle"}}>
                                <div
                                    style={{
                                        background: "#e9f2f7",
                                        padding: "20px",
                                        borderRadius: "20px",
                                        display: "table-cell",
                                    }}
                                >
                                    {text}
                                </div>
                            </div>
                            <div style={{display: "table-cell", verticalAlign: "middle"}}>
                                <div
                                    style={{
                                        display: "inline-block",
                                        alignItems: "center",
                                        borderTop: "0px solid transparent",
                                        borderLeft: "25px solid #e9f2f7",
                                        borderBottom: "25px solid transparent",
                                    }}
                                ></div>
                            </div>
                            <div style={{display: "table-cell", verticalAlign: "middle"}}>
                                <img
                                    src='https://hansschmidt.org/wp-content/uploads/2021/11/Eppy-thinking-pose.png'
                                    style={{verticalAlign: "middle"}}
                                    width='100'
                                    height='132'
                                ></img>
                            </div>
                        </div>
                    </div>
                    <form className={stylesD.root} noValidate autoComplete='off'>
                        <TextField
                            id='outlined-multiline-static'
                            label='Answer'
                            multiline
                            rows={8}
                            // defaultValue=''
                            variant='outlined'
                            value={answerData}
                            onChange={(e) => setAnswerData(e.target.value)}
                        />
                        {wordsInAnswer < 250 ?
                            <p style={{margin: '5px 0px 5px 10px'}}>You've typed {wordCount}. Can you dig deeper?</p>
                            :
                            <div>
                                {wordsInAnswer < 500 ?
                                    <p style={{margin: '5px 0px 5px 10px'}}>You've typed {wordCount}! Tell me more!</p>
                                    :
                                    <p style={{margin: '5px 0px 5px 10px'}}>You've typed {wordCount}! You have a great
                                        memory!</p>
                                }
                            </div>
                        }
                    </form>
                    <button
                        className={classes.submitButton}
                        type='submit'
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                    <button className={classes.cancelButton} onClick={handleClose}>
                        Cancel
                    </button>

                    <p style={{fontSize: "10px"}}>
                        All submissions must abide by the{" "}
                        <a href='/terms' target='_blank'>
                            Terms and Conditions
                        </a>
                        , which you accepted.
                    </p>
                </div>
            </div>
        </div>
    );

    const bodySubmitted = (
        <div style={modalStyle} className={styles.paper}>
            <div className={classes.thanksContainer}>
                <h1>Thank you!</h1>
                {window.location.href.indexOf("edit") > -1 ? (
                    <>
                        <h2>
                            You can view and update responses any time in the "Answers" tab
                        </h2>
                        <button className={classes.viewInProfile} onClick={handleClose}>
                            Close
                        </button>
                    </>
                ) : (
                    <>
                        <h2>You can view and update responses any time in your profile</h2>
                        <Link to='/profile'>
                            <button className={classes.viewInProfile}>
                                View in Profile{" "}
                                <ChevronRightIcon className={classes.chevRight}/>
                            </button>
                        </Link>
                    </>
                )}
            </div>
        </div>
    );

    // Form Input code

    // const [value, setValue] = React.useState("Controlled");

    if (!loading) {
        return (
            <>
                <div
                    className={classes.block}
                    onClick={handleOpen}
                    style={{
                        margin: userID ? "30px auto" : "30px",
                    }}
                >
                    <Typography className={classes.question}>{text}</Typography>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-describedby='simple-modal-description'
                >
                    {isSubmitted ? bodySubmitted : body}
                </Modal>
            </>
        );
    } else {
        return (
            <CircularProgress
                style={{
                    position: "fixed",
                    right: "50%",
                    top: "50%",
                }}
            ></CircularProgress>
        );
    }
};

// create function to set true or false, isSubmitted. setIsSubmitted
// {isSubmitted ? {bodySubmitted} : {body}}

export default QuestionBlock;
