import * as api from "../api";

// Action Creators - functions that return actions
// payload: data where we store all of our questions

// function getCookie(cname) {
//   let name = cname + "=";
//   let decodedCookie = decodeURIComponent(document.cookie);
//   let ca = decodedCookie.split(";");
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) == " ") {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) == 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return "";
// }

// Check token and load user
export const loadUser = () => (dispatch, getState) => {
  try {
    // User Loading
    dispatch({ type: "USER_LOADING" });

    // Get token from state
    const token = getState().auth.token;

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
      dispatch({ type: "USER_LOADED" });
    }
  } catch (error) {
    console.log(error.message);
    console.log(error.response.data.error_message);
    console.log(error.response.status);
  }
};

// LOGIN USER
export const login = (loginData) => async (dispatch) => {
  try {
    // // Get token from state
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // If token, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // Request Body
    const body = JSON.stringify({
      email: loginData.email,
      password: loginData.password,
    });
    const { data } = await api.login(body, config);
    if (data) {
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
    }
  } catch (error) {
    console.log(error.response.data.error_message);
    dispatch({ type: "LOGIN_FAIL" });
    dispatch({ type: "ADD_ERROR", payload: error.response.data.error_message });
  }
};

// LOGOUT USER
export const logout = () => (dispatch) => {
  try {
    dispatch({ type: "LOGOUT_SUCCESS" });
  } catch (error) {
    console.log(error.message);
    console.log(error.response.data.error_message);
    console.log(error.response.status);
  }
};

export const showLoader = () => (dispatch) => {
  dispatch({ type: "LOADING" });
};

export const hideLoader = () => (dispatch) => {
  dispatch({ type: "FINISH_LOADING" });
};

export const removeResponse = () => (dispatch) => {
  dispatch({ type: "REMOVE_RESPONSE" });
};

export const forgotPassword = (email, history, url) => async (dispatch) => {
  try {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Request Body
    const body = JSON.stringify({
      email,
      url
    });

    const resp  = await api.checkEmail(body, config);
    if (resp.data) {
      if (resp.data.status === "VALID") {
        const { data } = await api.sendResetEmail(resp.data.message, body, config);
        if (data.status === "PENDING") {
          history.push(`/emailsent/${email}/${true}`);
        } else if (data.status === "ERROR") {
            //check for specific error
            dispatch({ type: "ADD_ERROR", payload: data.message });
        }
      } else {
        dispatch({ type: "ADD_ERROR", payload: resp.data.message });
      }
    }
    
  } catch (error) {
    console.log('Error calling api:', error.message);
  }
};

// RESET PASSWORD
export const resetPassword = (userId, password, resetStr, history) => async (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    userId,
    password,
    resetStr
  });
  //check whether the resetStr is still valid
  // find the user, if the resetStr is valid, then update the password
  const { data } = await api.resetPassword(body, config);

  if (data.status === "SUCCESS") {
    history.push(`/emailsent/${true}`);
  } else if (data.status === "EXPIRE") {
    history.push('/forgot');
    dispatch({ type: "ADD_ERROR", payload: "The reset link has expired, please resent the request here!" });
  }
  else if (data.status === "ERROR") {
    // check for specific error
    const { message } = data;
    dispatch({ type: "RESET_PSW_FAIL" });
    dispatch({ type: "ADD_ERROR", payload: message });
  }
};

// DELETE PROFILE
export const removeProfile = () => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // console.log(`answer: ${answer}, id: ${id}`);
    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    const { data } = await api.deleteProfile(config);
    dispatch({ type: "ADD_RESPONSE", payload: data });
    dispatch({ type: "DELETE_PROFILE" });
  } catch (error) {
    console.log(error.message);
    console.log(error.response.data.error_message);
    console.log(error.response.status);
  }
};

export const getVerification = (id) => async (dispatch) => {
  try {
    // Get token from state
    // const token = getState().auth.token;

    // console.log("token");
    // console.table(token);

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // If token, add to headers
    // if (token) {
    //   config.headers["Authorization"] = `Token ${token}`;
    // }
    // console.log("CONFIG");
    // console.table(config);

    const { data } = await api.fetchVerification(id, config);
    console.table(data);
    dispatch({ type: "VERIFY_SUCCESS", payload: data });
    // Account has been activated
  } catch (error) {
    // Error
    console.log(error.response.status);
    dispatch({ type: "VERIFY_FAIL", payload: error.response.status });
  }
};
