import React, { useEffect } from "react";

import { Route, Redirect } from "react-router-dom";
import { Switch } from "react-router-dom";

import MainNavigation from "./components/layout/MainNavigation";
import Footer from "./components/layout/Footer";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import EmailSentPage from "./pages/EmailSentPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ProfilePage from "./pages/ProfilePage";
import AccountPage from "./pages/AccountPage";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import SiteMap from "./pages/SiteMap";
import TermsPage from "./pages/TermsPage";
import UserAccessPage from "./pages/UserAccessPage";
import NotFoundPage from "./pages/NotFoundPage";
import SecondaryUserAccessPage from "./pages/SecondaryUserAccessPage";
import SecondaryUserProfilePage from "./pages/SecondaryUserProfilePage";
import SearchPage from "./pages/SearchPage";
import Prices from "./pages/Prices"
import VerificationPage from "./pages/VerificationPage";

import { loadUser } from "./actions/auth";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

// render={() => {
//   return fakeAuth.isAuthenticated === true ? (children) : (<Redirect to='/login' />);
// }}

function PrivateRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.auth);
  
  return (
    <Route
      {...rest}
      render={() => {
        if (auth.isLoading) {
          console.log(auth.isLoading);
          return (
            <CircularProgress
              style={{
                position: "fixed",
                right: "50%",
                top: "50%",
              }}
            >
              Loading Profile
            </CircularProgress>
          );
        } else if (!auth.isAuthenticated) {
          return <Redirect to='/login' />;
        } else {
          return children;
        }
      }}
    />
  );
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route path='/' exact>
          <MainNavigation />
          <LandingPage />
          <Footer />
        </Route>
        <Route path='/register' exact>
          <RegisterPage />
        </Route>
        <Route path='/reset-password/:userId/:resetStr'>
          <ResetPasswordPage />
        </Route>
        <Route path='/forgot' exact>
          <ForgotPasswordPage />
        </Route>
        <Route path='/emailsent/:userEmail?/:reset?' exact>
          <EmailSentPage/> 
        </Route>
        <Route path='/login' exact>
          <LoginPage />
        </Route>
        <Route path='/about' exact>
          <MainNavigation />
          <AboutPage />
          <Footer />
        </Route>
        <Route path='/contact' exact>
          <MainNavigation />
          <ContactPage />
          <Footer />
        </Route>
        <Route path='/cookiepolicy' exact>
          <MainNavigation />
          <CookiePolicyPage />
          <Footer />
        </Route>
        <Route path='/privacypolicy' exact>
          <MainNavigation />
          <PrivacyPolicyPage />
          <Footer />
        </Route>
        <Route path='/sitemap' exact>
          <MainNavigation />
          <SiteMap />
          <Footer />
        </Route>
        <Route path='/terms' exact>
          <MainNavigation />
          <TermsPage />
          <Footer />
        </Route>
        <Route path='/verify/:id' exact>
          <MainNavigation />
          <VerificationPage />
          <Footer />
        </Route>
        <Route path='/search' exact>
          <MainNavigation />
          <SearchPage />
          <Footer />
        </Route>
        <Route path='/profile/:id' exact>
          <MainNavigation />
          <UserAccessPage />
          <Footer />
        </Route>
        <Route path='/secondary/:id' exact>
          <MainNavigation />
          <SecondaryUserAccessPage />
          <Footer />
        </Route>
        <PrivateRoute path='/prices'>
          <MainNavigation />
          <Prices></Prices>
          <Footer />
        </PrivateRoute>
        <PrivateRoute path='/home' exact>
          <MainNavigation />
          <HomePage />
          <Footer />
        </PrivateRoute>
        <PrivateRoute path='/profile' exact>
          <MainNavigation />
          <ProfilePage />
          <Footer />
        </PrivateRoute>
        <PrivateRoute path='/account' exact>
          <MainNavigation />
          <AccountPage />
          <Footer />
        </PrivateRoute>
        <PrivateRoute path='/edit/secondary-profile/:id' exact> 
          <MainNavigation />
          <SecondaryUserProfilePage />
          <Footer />
        </PrivateRoute>
        <Route>
          <MainNavigation />
          <NotFoundPage />
          <Footer />
        </Route>
      </Switch>
    </>
  );
}

export default App;
