import * as api from "../api";
import userTypes from "../types/user.js";

// Action Creators - functions that return actions
// the api.func ARE IMPORTED FROM API
// THESE FUNCTIONS are then used in components
// payload: data where we store all of our questions

// function getCookie(cname) {
//   let name = cname + "=";
//   let decodedCookie = decodeURIComponent(document.cookie);
//   let ca = decodedCookie.split(";");
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) == " ") {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) == 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return "";
// }

export const createUser = (userData) => async (dispatch) => {
  try {
    // // Get cookie
    // const cookie = getCookie("csrftoken");
    // console.log("THIS IS THE COOKIE");
    // console.table(cookie);

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   console.log("THIS IS THE COOKIE");
    //   console.table(cookie);
    //   config.headers["X-CSRFTOKEN"] = `${cookie}`;
    // }
    // console.table(config);

    //   destructure data from the response
    const { data } = await api.createUser(userData, config);
    // send to reducer to update or modify state
    dispatch({ type: "CREATE_USER", payload: data }); //I NEED THIS TO FAIL
    // dispatch({ type: "FIRST_LOGIN", payload: data });
    // console.log("RESPONSE FROM CREATE USER:" + data.token);
  } catch (error) {
    console.log(
      "error.response.error_message: " + error.response.error_message
    );
    dispatch({ type: "ADD_ERROR", payload: error.response.data.error_message });
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

export const getUser = (id) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // console.log("token");
    // console.table(token);

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    // console.log("CONFIG");
    // console.table(config);

    const { data } = await api.fetchUser(id, config);
    // console.table(data);

    dispatch({ type: userTypes.GET_USER, payload: data });
  } catch (error) {
    console.log(error.response.status);
    dispatch({ type: "ADD_ERROR_CODE", payload: error.response.status });
  }
};

export const updateName = (name) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    //   destructure data from the response
    const { data } = await api.putName(name, config);

    // send to reducer to update or modify state
    dispatch({ type: userTypes.UPDATE_NAME, payload: data });
    dispatch({ type: "UPDATE_AUTH_NAME", payload: data });
  } catch (error) {
    console.log(
      "error.response.error_message: " + error.response.error_message
    );
    dispatch({ type: "ADD_ERROR", payload: error.response.data.error_message });
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

export const updateBday = (bday) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    //   destructure data from the response
    const { data } = await api.putBday(bday, config);

    // send to reducer to update or modify state
    console.log("PALOAD DATA");
    console.table(data);
    dispatch({ type: userTypes.UPDATE_BDAY, payload: data });
  } catch (error) {
    console.log("error.response.error_message: " + error);
    // dispatch({ type: "ADD_ERROR", payload: error.response.data.error_message });
  }
};

export const updateBio = (bio) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    //   destructure data from the response
    const { data } = await api.putBio(bio, config);

    // send to reducer to update or modify state
    dispatch({ type: userTypes.UPDATE_BIO, payload: data });
  } catch (error) {
    console.log(
      "error.response.error_message: " + error.response.error_message
    );
    dispatch({ type: "ADD_ERROR", payload: error.response.data.error_message });
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

export const getAccount = () => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    const { data } = await api.fetchAccount(config);
    dispatch({ type: "GET_ACCOUNT", payload: data });
  } catch (error) {
    console.log(error.message);
    console.log(error.response.data.error_message);
    console.log(error.response.status);
  }
};

export const updatePrivacy = () => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // console.log("TOKEN");
    // console.table(token);

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    const bool = { value: false };

    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    // console.log("CONFIG");
    // console.table(config);

    //   destructure data from the response
    const { data } = await api.putPrivacy(bool, config);

    // send to reducer to update or modify state
    dispatch({ type: "ADD_RESPONSE", payload: data });
  } catch (error) {
    console.log(
      "error.response.error_message: " + error.response.error_message
    );
    dispatch({ type: "ADD_ERROR", payload: error.response.data.error_message });
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

export const updateEmail = (updatedEmail) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    //   destructure data from the response
    const { data } = await api.putEmail(updatedEmail, config);

    // send to reducer to update or modify state
    dispatch({ type: "ADD_RESPONSE", payload: data });
    dispatch({ type: userTypes.UPDATE_EMAIL, payload: data });
  } catch (error) {
    console.log(
      "error.response.error_message: " + error.response.error_message
    );
    dispatch({ type: "ADD_ERROR", payload: error.response.data.error_message });
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

export const updatePassword =
  (updatedPassword) => async (dispatch, getState) => {
    try {
      // Get token from state
      const token = getState().auth.token;

      // // Get cookie
      // const cookie = getCookie("csrftoken");

      // Headers
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // // If cookie, add to headers
      // if (cookie) {
      //   config.headers["Cookie"] = `csrftoken=${cookie}`;
      // }

      // If token, add to headers
      if (token) {
        config.headers["Authorization"] = `Token ${token}`;
      }

      //   destructure data from the response
      const { data } = await api.putPassword(updatedPassword, config);

      // send to reducer to update or modify state
      dispatch({ type: "ADD_RESPONSE", payload: data });
    } catch (error) {
      console.log(
        "error.response.error_message: " + error.response.error_message
      );
      dispatch({
        type: "ADD_ERROR",
        payload: error.response.data.error_message,
      });
      // console.log(error.response.data.error_message);
      // console.log(error.response.status);
    }
  };

export const addSecondaryUser = (newUser) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // console.log("TOKEN");
    // console.table(token);

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    //   destructure data from the response
    const { data } = await api.createSecondaryUser(newUser, config);

    // console.table(data);

    // send to reducer to update or modify state
    dispatch({ type: userTypes.ADD_SECONDARY_USER, payload: data });
    dispatch({ type: "ADD_RESPONSE", payload: data });
  } catch (error) {
    console.log("error.response" + error.response);
    dispatch({ type: "ADD_ERROR", payload: error.response });
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

export const getSecondaryUser = (id) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // console.log("token");
    // console.table(token);

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    // console.log("CONFIG");
    // console.table(config);

    const { data } = await api.fetchSecondaryUser(id, config);
    console.table(data);
    dispatch({ type: "GET_SECONDARY_USER", payload: data });
  } catch (error) {
    console.log(error.response.status);
    dispatch({ type: "ADD_ERROR_CODE", payload: error.response.status });
  }
};

export const removeSecondaryUser = (id) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // console.log(`answer: ${answer}, id: ${id}`);
    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    const { data } = await api.deleteSecondaryUser(id, config);
    dispatch({ type: "ADD_RESPONSE", payload: data });
    // console.log("FINISHED DELETING");
    // dispatch({ type: "DELETE_A", payload: data });
  } catch (error) {
    console.log(error.message);
    console.log(error.response.data.error_message);
    console.log(error.response.status);
  }
};

export const updateSecondaryUserName =
  (id, name) => async (dispatch, getState) => {
    try {
      // Get token from state
      const token = getState().auth.token;

      // // Get cookie
      // const cookie = getCookie("csrftoken");

      // Headers
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // // If cookie, add to headers
      // if (cookie) {
      //   config.headers["Cookie"] = `csrftoken=${cookie}`;
      // }

      // If token, add to headers
      if (token) {
        config.headers["Authorization"] = `Token ${token}`;
      }

      //   destructure data from the response
      const { data } = await api.putSecondaryName(id, name, config);

      // send to reducer to update or modify state
      dispatch({ type: "UPDATE_SECONDARY_NAME", payload: data });
    } catch (error) {
      console.log(
        "error.response.error_message: " + error.response.error_message
      );
      dispatch({
        type: "ADD_ERROR",
        payload: error.response.data.error_message,
      });
      // console.log(error.response.data.error_message);
      // console.log(error.response.status);
    }
  };

export const updateSecondaryUserBday =
  (id, bday) => async (dispatch, getState) => {
    try {
      // Get token from state
      const token = getState().auth.token;

      // // Get cookie
      // const cookie = getCookie("csrftoken");

      // Headers
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // // If cookie, add to headers
      // if (cookie) {
      //   config.headers["Cookie"] = `csrftoken=${cookie}`;
      // }

      // If token, add to headers
      if (token) {
        config.headers["Authorization"] = `Token ${token}`;
      }

      //   destructure data from the response
      const { data } = await api.putSecondaryBday(id, bday, config);

      // send to reducer to update or modify state
      dispatch({ type: "UPDATE_SECONDARY_BDAY", payload: data });
    } catch (error) {
      console.log(
        "error.response.error_message: " + error.response.error_message
      );
      dispatch({
        type: "ADD_ERROR",
        payload: error.response.data.error_message,
      });
      // console.log(error.response.data.error_message);
      // console.log(error.response.status);
    }
  };

export const updateSecondaryUserBio =
  (id, bio) => async (dispatch, getState) => {
    try {
      // Get token from state
      const token = getState().auth.token;

      // // Get cookie
      // const cookie = getCookie("csrftoken");

      // Headers
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // // If cookie, add to headers
      // if (cookie) {
      //   config.headers["Cookie"] = `csrftoken=${cookie}`;
      // }

      // If token, add to headers
      if (token) {
        config.headers["Authorization"] = `Token ${token}`;
      }

      //   destructure data from the response
      const { data } = await api.putSecondaryBio(id, bio, config);

      // send to reducer to update or modify state
      dispatch({ type: "UPDATE_SECONDARY_BIO", payload: data });
    } catch (error) {
      console.log(
        "error.response.error_message: " + error.response.error_message
      );
      dispatch({
        type: "ADD_ERROR",
        payload: error.response.data.error_message,
      });
      // console.log(error.response.data.error_message);
      // console.log(error.response.status);
    }
  };

export const updateSecondaryUserPrivacy =
  (id) => async (dispatch, getState) => {
    try {
      // Get token from state
      const token = getState().auth.token;

      // // Get cookie
      // const cookie = getCookie("csrftoken");

      // Headers
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // // If cookie, add to headers
      // if (cookie) {
      //   config.headers["Cookie"] = `csrftoken=${cookie}`;
      // }

      const bool = { value: false };

      // If token, add to headers
      if (token) {
        config.headers["Authorization"] = `Token ${token}`;
      }

      //   destructure data from the response
      const { data } = await api.putSecondaryPrivacy(id, bool, config);

      // send to reducer to update or modify state
      dispatch({ type: "ADD_RESPONSE", payload: data });
    } catch (error) {
      console.log(
        "error.response.error_message: " + error.response.error_message
      );
      dispatch({
        type: "ADD_ERROR",
        payload: error.response.data.error_message,
      });
      // console.log(error.response.data.error_message);
      // console.log(error.response.status);
    }
  };

export const createSecondaryUserAnswer =
  (secondary_user_profile_id, answer, id) => async (dispatch, getState) => {
    try {
      // Get token from state
      const token = getState().auth.token;

      // // Get cookie
      // const cookie = getCookie("csrftoken");

      // Headers
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // // If cookie, add to headers
      // if (cookie) {
      //   config.headers["Cookie"] = `csrftoken=${cookie}`;
      // }

      const body = {
        secondary_profile: `${secondary_user_profile_id}`,
        answer: `${answer}`,
        id: `${id}`,
      };

      // console.log(
      //   `answer: ${answer}, id: ${id}, userID: ${secondary_user_profile_id}`
      // );
      // If token, add to headers
      if (token) {
        config.headers["Authorization"] = `Token ${token}`;
      }
      // console.log(`token: ${token}`);
      // console.table(config.headers);

      const { data } = await api.postAnswer(body, id, config);
      dispatch({ type: "CREATE_SECONDARY_ANSWER", payload: data });
    } catch (error) {
      console.log("THIS IS THE ERROR: " + error.message);
      // console.log(error.response.data.error_message);
      // console.log(error.response.status);
    }
  };

export const updateSecondaryUserAnswer =
  (answer, id) => async (dispatch, getState) => {
    try {
      // Get token from state
      const token = getState().auth.token;

      // // Get cookie
      // const cookie = getCookie("csrftoken");

      // Headers
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // // If cookie, add to headers
      // if (cookie) {
      //   config.headers["Cookie"] = `csrftoken=${cookie}`;
      // }

      const body = {
        answer: `${answer.answer}`,
      };

      // console.log(`answer: ${answer}, id: ${id}`);
      // If token, add to headers
      if (token) {
        config.headers["Authorization"] = `Token ${token}`;
      }

      const { data } = await api.putAnswer(body, id, config);

      dispatch({ type: "UPDATE_SECONDARY_ANSWER", payload: data });
    } catch (error) {
      console.log(error.message);
      console.log(error.response.data.error_message);
      console.log(error.response.status);
    }
  };

export const removeSecondaryUserAnswer = (id) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // console.log(`answer: ${answer}, id: ${id}`);
    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    // console.log("THIS IS THE ID FROM DELETE SEC ANSWER:");
    // console.log(id);

    const { data } = await api.deleteAnswer(id, config);

    dispatch({ type: "DELETE_SECONDARY_ANSWER", payload: data });
  } catch (error) {
    console.log(error.message);
    console.log(error.response.data.error_message);
    console.log(error.response.status);
  }
};


export const createSubscription = async (subscription) => {
  
  try {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    const { data } = await api.createSubscription(subscription, config);
    
    return data
    
  } catch (error) {
    console.log(error);

  }
};

export const updateSubscription = async (subscription, token) => {
  
  try {

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
      }
    };

    const { data } = await api.updateSubscription(subscription, config);
    
    return data
    
  } catch (error) {
    console.log(error);

  }
};