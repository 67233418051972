export default (random = { id: 0, question: "" }, action) => {
  switch (action.type) {
    case "FETCH_RANDOM_QUESTION":
      //   console.log("THIS IS THE RANDOM QUESTION");
      //   console.log(action.payload);
      return action.payload;

    default:
      return random;
  }
};
