import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import QuestionBlock from "../questionBlock/QuestionBlock";

const QuestionGrid = ({
  currentItemArray,
  setCurrentItemArray,
  currentItems,
  loading,
  userID,
}) => {
  if (!loading) {
    return (
      <Grid container>
        {/* {console.log("Current items: ")}
    {console.table(currentItems)} */}

        {currentItems &&
          currentItems.map((question) => {
            //   console.log("IN MAIN IF");
            //   console.log("category is: " + category);
            //   console.log(question.category.category);
            return (
              <Grid key={question.id} item xs={12} sm={6} md={4}>
                <QuestionBlock
                  currentItemArray={currentItemArray}
                  setCurrentItemArray={setCurrentItemArray}
                  currentItems={currentItems}
                  text={question.question}
                  id={question.id}
                  userID={userID}
                />
              </Grid>
            );
          })}
      </Grid>
    );
  }
};

// create function to set true or false, isSubmitted. setIsSubmitted
// {isSubmitted ? {bodySubmitted} : {body}}

export default QuestionGrid;
