import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import classes from './stripe.module.scss'
import { createSubscription, updateSubscription } from "../../actions/user";

// MATERIAL UI
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { store } from "../../index";

export default function CheckoutForm({ subscription, setSubscription, user }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    cardholder: "",
  })

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    
    setIsLoading(true);

    const cardElement = elements.getElement(CardElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: `${user.first_name} ${user.last_name}`, 
        email: user.email, 
      }
    })

    if(error){
      console.log(error)
      setMessage('Invalid information') 
      setIsLoading(false)

    } else if (paymentMethod) {

      userData.user = user
      
      const data = {
        paymentMethod: paymentMethod,
        subscription: subscription,
        userData: userData
      }

      try {

        const responsePayment = await createSubscription(data)
        setMessage(responsePayment)
        setIsLoading(false)
        setSubscription('')
        return window.location.href = '/prices'
        // <Redirect to="/prices" />
        // window.location.href = '/prices'

      } catch (error) {
        console.log(error)
        setIsLoading(false)
        setMessage(error)
      }
      
    }

  };

  const submitUpdateSubscription = async () => {
    try {

      const token = store.getState().auth.token
      
      const data = {
        subscription: subscription,
        userData: user
      }

      const responseUpdate = await updateSubscription(data, token)
      setMessage(responseUpdate)
      setIsLoading(false)
      setSubscription('')
      return window.location.href = '/prices'
      // <Redirect to="/prices" />
      // window.location.href = '/prices'

    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setMessage(error)
    }
  }

  return (
    <>
    {subscription.title == 'Premium' && user.subscription == 'free' &&
    <div className={classes.container}>
      <div className={classes.formHeader}>
        <span onClick={ () => setSubscription('')}>Back</span>
        <h1 className={classes.headerTitle}>Subscription {subscription ? subscription.title : 'No Subscription Selected'}</h1>
      </div>
     
      <form onSubmit={handleSubmit} className={classes.paymentForm}>

        {/* CUSTOME DATA FIELDS */}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              style={{ color: "black" }}
              autoComplete='fname'
              name='cardHolder'
              value={userData.cardholder}
              variant='outlined'
              required
              fullWidth
              id='cardHolder'
              label='Cardholder Name'
              autoFocus
              onChange={(e) =>
                setUserData({ ...userData, cardholder: e.target.value })
              }
            />
          </Grid>
        </Grid>

        {/* STRIPE CARD ELEMENT DATA */}

        <CardElement className={classes.paymentCardElement}/>
        <button disabled={isLoading || !stripe || !elements} id="submit" className={classes.button}>
          <span className={classes.buttonText}>
            {isLoading ? <div className={classes.spinner}></div> : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div className={classes.paymentMessage}>{message}</div>}
      </form>
    </div>
    }
    {subscription.title == 'Basic' && user.subscription !== 'free' &&
    <div className={classes.container}>
      <div className={classes.formHeader}>
        <span onClick={ () => setSubscription('')}>Back</span>
        <h1 className={classes.headerTitle}>Subscription {subscription ? subscription.title : 'No Subscription Selected'}</h1>
      </div>
     
      <form onSubmit={submitUpdateSubscription} className={classes.paymentForm}>

        {/* CUSTOME DATA FIELDS */}
        <button disabled={isLoading || !stripe || !elements} id="submit" className={classes.button}>
          <span className={classes.buttonText}>
            {isLoading ? <div className={classes.spinner}></div> : "Switch to basic"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div className={classes.paymentMessage}>{message}</div>}
      </form>
    </div>
    }
    </>
  );
}