export default (
  secondaryUser = {
    id: "",
    first_name: "",
    last_name: "",
    bio: "",
    public: null,
    questions: null,
    answers: null,
    response: "",
    bday: "",
  },
  action
) => {
  switch (action.type) {
    // We get a response back from the backend, has data in payload.. what do we do with the data?

    case "GET_SECONDARY_USER":
      return {
        id: action.payload.id,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        bio: action.payload.bio,
        public: action.payload.public,
        questions: action.payload.questions,
        answers: action.payload.answers,
        response: action.payload.response,
        bday: action.payload.bday,
        pid: action.payload.pid,
        pfname: action.payload.pfname,
        plname: action.payload.plname,
      };

    case "UPDATE_SECONDARY_NAME":
      return {
        ...secondaryUser,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
      };

    case "UPDATE_SECONDARY_ANSWER":
    case "DELETE_SECONDARY_ANSWER":
      // console.log("UPDATE OR DELETE_SECONDARY_ANSWER CALLED:");
      // console.table(action.payload);
      // console.table(action.payload.answer);
      return {
        ...secondaryUser,
        answers: action.payload.answer,
      };

    case "UPDATE_SECONDARY_BIO":
      // console.log("UPDATE BIO PAYLOAD: " + action.payload.biography);
      return {
        ...secondaryUser,
        bio: action.payload.biography,
      };

    case "CREATE_SECONDARY_ANSWER":
      // spread answers, add the current answer to the list
      // console.log("CREATE_SECONDARY_ANSWER CALLED:");
      // console.table(action.payload);
      return {
        ...secondaryUser,
        answers: [...secondaryUser.answers, action.payload],
      };

    case "UPDATE_SECONDARY_BDAY":
      // console.log("UPDATE BIO PAYLOAD: " + action.payload.biography);
      return {
        ...secondaryUser,
        bday: action.payload.bday,
      };

    default:
      return secondaryUser;
  }
};
