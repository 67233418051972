export const subscriptions = [
  {
    title: 'Basic',
    features: [
      'Lorem Ipsum',
      'Lorem Ipsum',
      'Lorem Ipsum'
    ],
    price: 0
  },
  {
    title: 'Premium',
    features: [
      'Lorem Ipsum',
      'Lorem Ipsum',
      'Lorem Ipsum',
      'Lorem Ipsum'
    ],
    price: 4.99,
    product: 'price_1LNBXWKEjFBVRxv2LBgg6UvL'
  }
]