import * as api from "../api";

// Action Creators - functions that return actions
// the api.func ARE IMPORTED FROM API
// THESE FUNCTIONS are then used in components
// payload: data where we store all of our questions

// function getCookie(cname) {
//   let name = cname + "=";
//   let decodedCookie = decodeURIComponent(document.cookie);
//   let ca = decodedCookie.split(";");
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) == " ") {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) == 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return "";
// }

export const getSearchResults = (string) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // console.log("token");
    // console.table(token);

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    // console.log("CONFIG");
    // console.table(config);

    const { data } = await api.fetchSearch(string, config);
    dispatch({ type: "GET_SEARCH_RESULTS", payload: data });
  } catch (error) {
    console.log(error.response.status);
    dispatch({ type: "ADD_ERROR_CODE", payload: error.response.status });
  }
};
