import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";
const stripePromise = loadStripe("pk_test_51LEigUKEjFBVRxv2iIfLlVmrVFP6JnDbKU6FOWYliQmFy1C6XqIULzz61OLIqmCQNYC4bNZKqZOfglViCmF1JSMh00yyQKO1mS");

const Stripe = ({ subscription, setSubscription, user }) => {

  return (
    <>
      <Elements stripe={stripePromise}>
        <CheckoutForm
          setSubscription={setSubscription}
          subscription={subscription}
          user={user}
        />
      </Elements>
    </>
  )
}

export default Stripe
