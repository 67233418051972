import { combineReducers } from "redux";
import questions from "./questions";
import answers from "./answers";
import user from "./user";
import auth from "./auth";
import secondaryUser from "./secondaryUser";
import secondaryQuestions from "./secondaryQuestions";
import searchResults from "./searchResults";
import categories from "./categories";
import random from "./random";

export default combineReducers({
  questions,
  answers,
  user,
  auth,
  secondaryUser,
  secondaryQuestions,
  searchResults,
  categories,
  random,
});
