import React from "react";
import { useParams } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import styles from "./LoginPage.module.scss";

import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Copyright from "../components/ui/Copyright";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
  paper: {
    backgroundColor: "transparent",
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function EmailSentPage() {
  const { userEmail, reset } = useParams();

  const classes = useStyles();

  return (
    <>
      <Container component='main' maxWidth='xs' style={{ height: "100vh" }}>

        <CssBaseline />
        <div
          className={classes.paper}
          style={{ backgroundColor: "transparent" }}
        >
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5' align='center'>
            Password Reset
          </Typography>
          {reset === "true" && (
          <>
            <p id='simple-modal-description' className={styles.modalQuestion}>
              We sent a verification link to your email address <b>{userEmail}.</b> Please click the link in your email to reset your password.      
            </p>
            <Grid container>
              <Grid item>
                <Link href={'/login'} variant='body2'>
                  {"Go to LogIn Page"}
                </Link>
              </Grid>
            </Grid>
          </>)}

          {reset !== "true" && (
          <>
            <p id='simple-modal-description' className={styles.modalQuestion}>Your password has been reset successfully, you may now login!</p>
            <Grid container>
              <Grid item>
                <Link href='/login' variant='body2'>
                  {"Go to LogIn Page"}
                </Link>
              </Grid>
            </Grid>
          </>
          )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </>
  );
}
