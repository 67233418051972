import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";

import classes from "./UserAccessPage.module.scss";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { getUser } from "../actions/user";
import AccessQA from "../components/accessQA/AccessQA";

import ShareAndReport from "../components/ui/ShareAndReport";

const UserAccess = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const responseCode = useSelector((state) => state.auth.response_code);
  let { id } = useParams();

  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch]);

  if (responseCode === 404 || responseCode === 400) {
    return <Redirect to='/404' />;
  }

  let bdayYear = "";
  if (user.bday) {
    bdayYear = user.bday.substring(0, 4);
  }

  const answerSection = () => {
    if (!user.answers) {
      return null;
    } else {
      return (
        <>
          {user.answers.map((answer, index) => {
            return (
              <AccessQA
                key={index}
                text={answer.answer}
                match={index}
                id={answer.id}
              />
            );
          })}
        </>
      );
    }
  };

  return (
    <div className={classes.container}>
      <div>
        <ShareAndReport />
        <h2>
          {user.first_name} <span>{user.last_name}</span>'s Life Story
        </h2>
        {bdayYear ? <p>Born {bdayYear}</p> : null}
        <div className={classes.line}></div>
        <p>{user.bio}</p>
        {answerSection()}
      </div>
    </div>
  );
};

export default UserAccess;
