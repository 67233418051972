import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import QuestionBlock from "../components/questionBlock/QuestionBlock";
import QuestionGrid from "../components/questionGrid/QuestionGrid";

import classes from "./HomePage.module.scss";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";

import {
    getQuestions,
    getCategories,
    getCategoryQuestions,
    getRandomQuestion,
} from "../actions/questions";
import MuiAlert from "@material-ui/lab/Alert";

import {removeResponse} from "../actions/auth";

import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {createAnswer} from "../actions/answers";
import {showLoader, hideLoader} from "../actions/auth";

import ReactPaginate from "react-paginate";
import styled from "styled-components";

// You can style your pagination component
// thanks to styled-components.
// Use inner class names to style the controls.
const MyPaginate = styled(ReactPaginate).attrs({
    // You can redifine classes here, if you want.
    activeClassName: "active", // default to "disabled"
})`
  display: flex;
  flex-basis: fit-content;
  max-width: 400px;
  justify-content: space-between;
  padding: 0px;
  margin: 30px auto;
  list-style-type: none;
  li {
    transition: all 0.1s ease-in-out;
  }
  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    // border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li:hover {
    transform: scale(1.2);
  }
  li.active a {
    background-color: #4e9a9b;
    border-color: transparent;
    color: white;
    // min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "90%",
        },
    },
    paper: {
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.background.paper,
        border: "none",
    },
}));

const HomePage = () => {
    // hook to dispatch an action
    const dispatch = useDispatch();

    // have access to whole global redux store
    const questions = useSelector((state) => state.questions);
    const categoryList = useSelector((state) => state.categories);
    const randomQuestion = useSelector((state) => state.random);
    const auth = useSelector((state) => state.auth);
    const [response, setResponse] = useState("");
    // const [randomIndex, setRandomIndex] = useState(null);
    // const [randomQuestionText, setRandomQuestionText] = useState("");

    const [category, setCategory] = useState("");

    // We start with an empty list of items.
    const [currentItemArray, setCurrentItemArray] = useState([]);
    const [currentItems, setCurrentItems] = useState(null);

    // page count for pagination
    const [pageCount, setPageCount] = useState(0);

    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 6;

    // BELOW: All for random button and modal

    const loading = useSelector((state) => state.auth.compLoad);
    const [answerData, setAnswerData] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const styles = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        dispatch(showLoader());
        setCategory(event.target.value);
        // console.log("THIS IS CAT:" + event.target.value);
        // send getQuestions with the category string
        dispatch(getCategoryQuestions(event.target.value));
        // return all questions of that category
        // set currentItemsArray with those questions
        setCurrentItemArray(questions);
        // Then, that should update currentItems
        setTimeout(function () {
            // dispatch(getCategoryQuestions(category));
            // setCurrentItemArray(questions);
            dispatch(hideLoader());
        }, 700);
    };

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        // setCurrentItems depends on the currentItemArray
        // Could be normal questions, or category
        setCurrentItems(currentItemArray.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(currentItemArray.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, currentItemArray]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % currentItemArray.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    // useEffect(() => {
    //   setCurrentItemArray(
    //     questions.filter((question) => question.category.category == category)
    //   );
    //   // dispatch(getRandomQuestion());
    //   // dispatch(showLoader());
    //   // dispatch(getCategories());
    //   // dispatch(getQuestions());
    // }, [dispatch]);

    useEffect(() => {
        // dispatch(getQuestions());
        // dispatch(getCategoryQuestions(category));

        if (category) {
            setCurrentItemArray(questions);
            // dispatch(getCategoryQuestions(category));
        } else {
            setCurrentItemArray(questions);
        }
    }, [dispatch, questions, category]);

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getRandomQuestion());

        dispatch(showLoader());
        dispatch(getQuestions());
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        // setCurrentItemArray(
        //   questions.filter((question) => question.category.category == category)
        // );
        setCurrentItemArray(questions);
        setTimeout(function () {
            dispatch(removeResponse());
        }, 3000);
        setTimeout(function () {
            dispatch(hideLoader());
        }, 700);
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // dispatch(showLoader());
        setOpen(false);
        setIsSubmitted(false);
        dispatch(getRandomQuestion());
        // dispatch(getRandomQuestion());
        // dispatch(getQuestions());
        // setTimeout(function () {
        //   dispatch(hideLoader());
        // }, 700);
        setAnswerData("");
    };

    const handleSubmit = (e) => {
        // once user submits, we want to send over a post request with all the data they typed in
        e.preventDefault();
        dispatch(createAnswer(answerData, randomQuestion.id));
        dispatch(getRandomQuestion());
        // When we submit, we want to remove this question form the currentItemArray

        setIsSubmitted(true);
        setAnswerData("");
    };

    const stylesD = useStyles();

    // const randomQuestion = questions.find((x) => x.id === randomIndex);
    // console.log(randomIndex);
    // console.table(randomQuestion);
    // console.log(questions);
    // console.table(questions[randomIndex]);
    // console.table(questions[randomIndex].question);
    // console.table("QUESTION ID: " + questions[randomIndex]);
    // console.log("QUESTION TEXT: " + questions[randomIndex]);

    const wordsInAnswer = answerData ? answerData.trim().split(' ').filter(w => w != "").length : 0;
    const wordCount = wordsInAnswer === 1 ? '1 word' : `${wordsInAnswer} words`;
    const wordGoal = 500;
    const wordsToGoal = wordGoal - wordsInAnswer;

    const body = (
        <div style={modalStyle} className={styles.paper}>
            <div style={modalStyle} className={classes.innerContainer}>
                <div id='simple-modal-description' className={classes.modalQuestion}>
                    <div style={{display: "table"}}>
                        <div style={{display: "table-row"}}>
                            <div style={{display: "table-cell", verticalAlign: "middle"}}>
                                <div
                                    style={{
                                        background: "#e9f2f7",
                                        padding: "20px",
                                        borderRadius: "20px",
                                        display: "table-cell",
                                    }}
                                >
                                    {randomQuestion.question}
                                </div>
                            </div>
                            <div style={{display: "table-cell", verticalAlign: "middle"}}>
                                <div
                                    style={{
                                        display: "inline-block",
                                        alignItems: "center",
                                        borderTop: "0px solid transparent",
                                        borderLeft: "25px solid #e9f2f7",
                                        borderBottom: "25px solid transparent",
                                    }}
                                ></div>
                            </div>
                            <div style={{display: "table-cell", verticalAlign: "middle"}}>
                                <img
                                    src='https://hansschmidt.org/wp-content/uploads/2021/11/Eppy-thinking-pose.png'
                                    style={{verticalAlign: "middle"}}
                                    width='100'
                                    height='132'
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>
                <form className={stylesD.root} noValidate autoComplete='off'>
                    <TextField
                        id='outlined-multiline-static'
                        label='Answer'
                        multiline
                        rows={8}
                        // defaultValue=''
                        variant='outlined'
                        value={answerData}
                        onChange={(e) => setAnswerData(e.target.value)}
                    />
                    {wordsInAnswer < 250 ?
                        <p style={{margin: '5px 0px 5px 10px'}}>You've typed {wordCount}. Can you dig deeper?</p>
                        :
                        <div>
                            {wordsInAnswer < 500 ?
                                <p style={{margin: '5px 0px 5px 10px'}}>You've typed {wordCount}! Tell me more!</p>
                                :
                                <p style={{margin: '5px 0px 5px 10px'}}>You've typed {wordCount}! You have a great
                                    memory!</p>
                            }
                        </div>
                    }
                </form>
                <button
                    className={classes.submitButton}
                    type='submit'
                    onClick={handleSubmit}
                >
                    Submit
                </button>
                <button className={classes.cancelButton} onClick={handleClose}>
                    Cancel
                </button>
                <p style={{fontSize: "10px"}}>
                    All submissions must abide by the{" "}
                    <a href='/terms' target='_blank'>
                        Terms and Conditions
                    </a>
                    , which you accepted.
                </p>
            </div>
        </div>
    );

    const bodySubmitted = (
        <div style={modalStyle} className={styles.paper}>
            <div className={classes.thanksContainer}>
                <h1>Thank you!</h1>
                {window.location.href.indexOf("edit") > -1 ? (
                    <>
                        <h2>
                            You can view and update responses any time in the "Answers" tab
                        </h2>
                        <button className={classes.viewInProfile} onClick={handleClose}>
                            Close
                        </button>
                    </>
                ) : (
                    <>
                        <h2>You can view and update responses any time in your profile</h2>
                        <Link to='/profile'>
                            <button className={classes.viewInProfile}>
                                View in Profile{" "}
                                <ChevronRightIcon className={classes.chevRight}/>
                            </button>
                        </Link>
                    </>
                )}
            </div>
        </div>
    );

    const paginate = (
        <MyPaginate
            breakLabel='..'
            nextLabel='>'
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel='<'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
        />
    );

    if (!loading) {
        return (
            <>
                <div className={classes.container}>
                    {/* SET THE RESPONSE */}
                    {auth.response && (
                        <Alert
                            style={{marginBottom: "20px"}}
                            severity='success'
                            onClick={() => {
                                dispatch({type: "REMOVE_RESPONSE"});
                            }}
                        >
                            {auth.response || response}
                        </Alert>
                    )}
                    <h1 className={classes.rules}>
                        Start answering questions to have them added to your profile!
                    </h1>
                    <h1 className={classes.rules2}>
                        Choose a category, or let Eppy choose a random question for you!
                    </h1>
                    {/* <select name='categories' id='questionCategories'>
        {categoryList.map((val) => {
          return <option value={val}>{val}</option>;
        })}
      </select> */}

                    {/* CATEGORIES */}
                    <div className={classes.topButtons}>
                        <FormControl style={{minWidth: "120px"}}>
                            <InputLabel id='CategorySelect'>Categories</InputLabel>

                            <Select
                                labelId='SelectLabelId'
                                id='SelectId'
                                value={category}
                                onChange={handleChange}
                                label='Categories'
                            >
                                <MenuItem value=''></MenuItem>
                                {categoryList.map((val) => {
                                    return <MenuItem value={val}>{val}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>

                        <button className={classes.randomButton} onClick={handleOpen}>
                            Let Eppy Pick
                        </button>

                        <img
                            src='https://hansschmidt.org/wp-content/uploads/2021/11/Eppy-waving-pose-mirrored.png'
                            width='83'
                            height='100'
                            alt='Let Eppy Pick'
                        ></img>
                    </div>

                    {/* THIS IS THE PAGINATION */}

                    {paginate}

                    {/* THIS IS THE QUESTION LIST GRID */}

                    <QuestionGrid
                        currentItemArray={currentItemArray}
                        setCurrentItemArray={setCurrentItemArray}
                        currentItems={currentItems}
                        loading={loading}
                    />
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-describedby='simple-modal-description'
                >
                    {isSubmitted ? bodySubmitted : body}
                </Modal>
            </>
        );
    } else {
        return (
            <CircularProgress
                style={{
                    position: "fixed",
                    right: "50%",
                    top: "50%",
                }}
            ></CircularProgress>
        );
    }
};

export default HomePage;
