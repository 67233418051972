import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MuiAlert from "@material-ui/lab/Alert";
import { removeResponse } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetPassword } from "../actions/auth";
import Copyright from "../components/ui/Copyright";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
  paper: {
    backgroundColor: "transparent",
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPasswordPage() {
  const auth = useSelector((state) => state.auth);
  const classes = useStyles();
  let { userId, resetStr} = useParams();

  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [conNewPass, setConNewPass] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(removeResponse());
  }, []);

  useEffect(() => {
    setError(auth.response);
  }, [auth.response]);

  // once user submits, we want check whether the new password is ok
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newPassword || newPassword !== conNewPass) {
      setError("Password is empty or passwords do not match!");
      setTimeout(function () {
        setError("");
      }, 3000);
      return;
    } else if (newPassword.length < 6) {
      setError("Password must be 6 or more characters");
      setTimeout(function () {
        setError("");
      }, 3000);
      return;
    } else {
      dispatch(resetPassword(userId, newPassword, resetStr, history));
    }
  };

  return (
    <>
      <Container component='main' maxWidth='xs' style={{ height: "100vh" }}>
        
        <CssBaseline />
        <div
          className={classes.paper}
          style={{ backgroundColor: "transparent" }}
        >
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Reset Password
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='newPassword'
              label='New Password'
              type='password'
              id='password'
              autoComplete='current-password'
              onChange={(e) =>
                setNewPassword(e.target.value)
              }
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='confirmNewPassword'
              label='Confirm New Password'
              type='password'
              id='password'
              autoComplete='current-password'
              onChange={(e) =>
                setConNewPass(e.target.value)
              }
            />
            {/* SET THE ERROR */}

            {error && (
              <Alert
                severity='error'
                onClick={() => {
                  setError(null);
                }}
              >
                {auth.response || error}
              </Alert>
            )}

            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
