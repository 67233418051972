export default (secondaryQuestions = [], action) => {
  switch (action.type) {
    case "FETCH_SECONDARY_QUESTIONS":
      return action.payload.questions;

    case "UPDATE_SECONDARY_QUESTIONS":
      // console.log("UPDATE SEC QUESTION PAYLOAD: ");
      // console.table(action.payload);
      return action.payload.questions;

    default:
      return secondaryQuestions;
  }
};
