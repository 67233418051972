import Subscriptions from '../components/payments/subscriptions'
import Stripe from '../components/payments/stripe'
import styles from "./ProfilePage.module.scss";
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../actions/user";

const Pricing = ({}) => {
  const dispatch = useDispatch()
  const [subscription, setSubscription] = useState()
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getUser(auth.id));
  }, [dispatch, auth.id, auth.response]);

  return (
    <>
     <div className={styles.container}>
      <div className={styles.padding}>
      {!subscription && 
        <Subscriptions
          setSubscription={setSubscription}
          user={user}
        />
      }
      {subscription &&
        <Stripe 
          setSubscription={setSubscription}
          subscription={subscription}
          user={user}
        />
      }
      </div>
    </div>
    </>
  )
}

export default Pricing
