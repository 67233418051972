// Action Creators - functions that return actions
// payload: data where we store all of our questions

// const url = "https://everypast-demo.com/api/";
const url = "http://127.0.0.1:8000/api/";

export const getQuestions = () => (dispatch, getState) => {
  try {
    const token = getState().auth.token;

    // Headers

    // If token, add to headers

    fetch(`${url}user/unanswered-questions/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      // .then((result) => console.log(result))
      .then((result) => dispatch({ type: "FETCH_ALL", payload: result }))
      .catch((error) => console.log("error", error));

    // console.log("HEADERS:");
    // console.table(token);

    // console.table(data);
    // console.log(data);

    // const { data } = await api.fetchQuestions(config.headers);
    // dispatch({ type: "FETCH_ALL" });
  } catch (error) {
    console.log(error.message);
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

export const getCategoryQuestions = (category) => (dispatch, getState) => {
  try {
    const token = getState().auth.token;

    // Headers

    // If token, add to headers
    // console.log(category);
    // console.log("THis is the response: ");

    fetch(`${url}user/unanswered-questions/category/${category}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      // .then((response) => console.table(response))
      .then((response) => response.json())
      // .then((result) => console.log(result))
      .then((result) => dispatch({ type: "UPDATE_QUESTIONS", payload: result }))
      .catch((error) => console.log("error", error));

    // console.log("HEADERS:");
    // console.table(token);

    // console.table(data);
    // console.log(data);

    // const { data } = await api.fetchQuestions(config.headers);
    // dispatch({ type: "FETCH_ALL" });
  } catch (error) {
    console.log(error.message);
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

// export const getQuestions = () => {
//   fetch("${url}user/unanswered-questions", {
//     method: "GET",
//     headers: {
//       "content-type": "application/json",
//       Authorization: "Token 36bfde9ba67c4e8d94be7357ce04909898ad1edb",
//     },
//   })
//     .then((response) => response.json())
//     .then((result) => console.log(result))
//     .catch((error) => console.log("error", error));
// };

export const getSecondaryUserQuestions = (id) => (dispatch, getState) => {
  try {
    const token = getState().auth.token;

    // Headers

    // If token, add to headers

    fetch(`${url}secondary-user/${id}/unanswered-questions/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      // .then((result) => console.log(result))
      .then((result) =>
        dispatch({ type: "FETCH_SECONDARY_QUESTIONS", payload: result })
      )
      .catch((error) => console.log("error", error));

    // console.table(data);
    // console.log(data);

    // const { data } = await api.fetchQuestions(config.headers);
    // dispatch({ type: "FETCH_SECONDARY_QUESTIONS" });
  } catch (error) {
    console.log(error.message);
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

export const getCategories = () => (dispatch, getState) => {
  try {
    const token = getState().auth.token;

    // Headers

    // If token, add to headers

    fetch(`${url}categories/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      // .then((result) => console.log(result))
      .then((result) => dispatch({ type: "FETCH_CATEGORIES", payload: result }))
      .catch((error) => console.log("error", error));

    // console.log("HEADERS:");
    // console.table(token);

    // console.table(data);
    // console.log(data);

    // const { data } = await api.fetchQuestions(config.headers);
    // dispatch({ type: "FETCH_ALL" });
  } catch (error) {
    console.log(error.message);
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

export const getRandomQuestion = () => (dispatch, getState) => {
  try {
    const token = getState().auth.token;

    // Headers

    // If token, add to headers

    fetch(`${url}user/random-question/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      // .then((result) => console.log(result))
      .then((result) =>
        dispatch({ type: "FETCH_RANDOM_QUESTION", payload: result })
      )
      .catch((error) => console.log("error", error));

    // console.log("HEADERS:");
    // console.table(token);

    // console.table(data);
    // console.log(data);

    // const { data } = await api.fetchQuestions(config.headers);
    // dispatch({ type: "FETCH_ALL" });
  } catch (error) {
    console.log(error.message);
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

export const getSecondaryUserRandomQuestion = (id) => (dispatch, getState) => {
  try {
    const token = getState().auth.token;

    // Headers

    // If token, add to headers

    fetch(`${url}secondary-user/${id}/random-question/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      // .then((response) => console.table(response.json()))
      .then((response) => response.json())
      // .then((result) => console.log(result))
      .then((result) =>
        dispatch({ type: "FETCH_RANDOM_QUESTION", payload: result })
      )
      .catch((error) => console.log("error", error));

    // console.log("HEADERS:");
    // console.table(token);

    // console.table(data);
    // console.log(data);

    // const { data } = await api.fetchQuestions(config.headers);
    // dispatch({ type: "FETCH_ALL" });
  } catch (error) {
    console.log(error.message);
    // console.log(error.response.data.error_message);
    // console.log(error.response.status);
  }
};

export const getSecondaryUserCategoryQuestions =
  (id, category) => (dispatch, getState) => {
    try {
      const token = getState().auth.token;

      // Headers

      // If token, add to headers

      // console.log("HERE IS getSecUserCatQues RESPONSE: ");
      fetch(
        `${url}secondary-user/${id}/unanswered-questions/category/${category}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
        // .then((response) => console.table(response))
        .then((response) => response.json())
        // .then((result) => console.log(result))
        .then((result) =>
          dispatch({ type: "UPDATE_SECONDARY_QUESTIONS", payload: result })
        )
        .catch((error) => console.log("error", error));

      // console.log("HEADERS:");
      // console.table(token);

      // console.table(data);
      // console.log(data);

      // const { data } = await api.fetchQuestions(config.headers);
      // dispatch({ type: "FETCH_ALL" });
    } catch (error) {
      console.log(error.message);
      // console.log(error.response.data.error_message);
      // console.log(error.response.status);
    }
  };
