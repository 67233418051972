import * as api from "../api";

// Action Creators - functions that return actions
// payload: data where we store all of our answers

// function getCookie(cname) {
//   let name = cname + "=";
//   let decodedCookie = decodeURIComponent(document.cookie);
//   let ca = decodedCookie.split(";");
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) == " ") {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) == 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return "";
// }

export const createAnswer = (answer, id) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    const body = {
      answer: `${answer}`,
      id: `${id}`,
    };

    // console.log(`answer: ${answer}, id: ${id}`);
    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    // console.log(`token: ${token}`);
    // console.table(config.headers);

    const { data } = await api.postAnswer(body, id, config);
    dispatch({ type: "CREATE_ANSWER", payload: data });
  } catch (error) {
    console.log(error.message);
    console.log(error.response.data.error_message);
    console.log(error.response.status);
  }
};

export const updateAnswer = (answer, id) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    const body = {
      answer: `${answer.answer}`,
    };

    // console.log(`answer: ${answer}, id: ${id}`);
    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    const { data } = await api.putAnswer(body, id, config);

    dispatch({ type: "UPDATE_ANSWER", payload: data });
  } catch (error) {
    console.log(error.message);
    console.log(error.response.data.error_message);
    console.log(error.response.status);
  }
};

export const removeAnswer = (id) => async (dispatch, getState) => {
  try {
    // Get token from state
    const token = getState().auth.token;

    // // Get cookie
    // const cookie = getCookie("csrftoken");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // // If cookie, add to headers
    // if (cookie) {
    //   config.headers["Cookie"] = `csrftoken=${cookie}`;
    // }

    // console.log(`answer: ${answer}, id: ${id}`);
    // If token, add to headers
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    const { data } = await api.deleteAnswer(id, config);

    dispatch({ type: "DELETE_ANSWER", payload: data });
  } catch (error) {
    console.log(error.message);
    console.log(error.response.data.error_message);
    console.log(error.response.status);
  }
};
